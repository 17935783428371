const ynTripList = [
  {
    name: "昆明丨丽江丨大理 6天5晚",
    prize: 498,
    linkName: "",
    typeName:'昆明丨丽江丨大理 6天5晚',
    type:'6天5晚',
    typeList:[{
        typeName:'6天5晚'
    }],
    smallImages:'http://dspcloud.spay365.com/98a1a4cdbae87f3b03041ad90a079e8036f27aeb.jpg',
    orderDetialImage:'http://dspcloud.spay365.com/f9d117d33f0b781522bfb6f4e9328d25ce646755.png',
    bannerList: [
      {
        imgUrl:
          "http://dspcloud.spay365.com/bca68f24d72e6da370b71e83e7a448db731a3d7f.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/78be77f632240b45e5cdd0df73bf4451cb5b9f49.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/76d702c45a17181faaa1d280ecfc1c00ff7431f8.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/0e817ab6d547e59ef97b2d379d283a82db3df5a4.png",
      },
    ],
    detailImage: "http://dspcloud.spay365.com/37674936b5e372ad0b50c5d77af17701a2923e15.jpg",
    productNo:'yntrip'
  },
  {
    name: "昆明丨芒市丨瑞丽丨腾冲 6天5晚",
    prize: 498,
    linkName: "KMRT",
    type:'6天5晚',
    typeName:'昆明丨芒市丨瑞丽丨腾冲 6天5晚',
    productNo:'yntripmrt',
    smallImages:'http://dspcloud.spay365.com/0ab040885c3a53f60769b4ce873a367f9a8523be.jpg',
    typeList:[{
        typeName:'6天5晚'
    }],
    bannerList: [
      {
        imgUrl:
          "http://dspcloud.spay365.com/458d45d8580c2d9cd38ba565cf123c14ed409708.png",
      }, {
        imgUrl:
          "http://dspcloud.spay365.com/9defb9763f7065dc92f56fa14f43d34e30969047.png",
      }, {
        imgUrl:
          "http://dspcloud.spay365.com/b9eb325e13f85018c291102ada26e8b57243f47a.png",
      },{
        imgUrl:
          "http://dspcloud.spay365.com/4518295e442835ed8e9eea428f110b3a955e24ba.png",
      }
    ],
    detailImage: "http://dspcloud.spay365.com/2c0c11dca0a289ef2198e51944cd5c95a1563cb7.jpg",
  },
  {
    name: "昆明丨西双版纳 6天5晚",
    prize: 498,
    type:'6天5晚',
    linkName: "KMRT",
    typeName:'昆明丨西双版纳 6天5晚',
    productNo:'yntripx',
    typeList:[{
        typeName:'6天5晚'
    }],
    smallImages:'http://dspcloud.spay365.com/82f31095158b3f7a22c1c2fa80bdbc247be87288.jpg',
    bannerList: [
      {
        imgUrl:
          "http://dspcloud.spay365.com/92508b80bbc75afb0b9f08e2bf44e6408e2e2479.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/78260406bd6f1db9516a1b10f7b5fe40050a91a3.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/47c8a641a7d5701096e6d5d6eb799548842379db.png",
      },{
        imgUrl:
          "http://dspcloud.spay365.com/b986e1d4392b71b4fdf42bf4174abf9f36dbade8.png",
      }
    ],
    detailImage: "http://dspcloud.spay365.com/7dbd20012086750eef60bccfdbcde21bda1a8b13.jpg",
  },
  {
    name: "昆明丨大理丨丽江丨泸沽湖 8天7晚",
    prize: 598,
    linkName: "KDLL",
    type:'8天7晚',
    typeName:'昆明丨大理丨丽江丨泸沽湖 8天7晚',
    productNo:'yntripdll',
    typeList:[{
        typeName:'8天7晚'
    }],
    smallImages:'http://dspcloud.spay365.com/a30d2ff7caf78a54b5729a16e4722aee739bc438.jpg',
    bannerList: [
      {
        imgUrl:
          "http://dspcloud.spay365.com/fafa8b28691d31171db7a9d12c6e0f16aff0b9b5.png",
      },{
        imgUrl:
          "http://dspcloud.spay365.com/46678aa56b72c886a04cfecb9294b9ab82059125.png",
      },{
        imgUrl:
          "http://dspcloud.spay365.com/91d23f22aaa709d04833dc813d900737e4c1323a.png",
      },{
        imgUrl:
          "http://dspcloud.spay365.com/0e3f956102013d494d47d38cbb4dad035a9b5691.png",
      }
    ],
    detailImage: "http://dspcloud.spay365.com/8d645b6b82929f2a952437943412f13f22dc4b08.jpg",
  },
  {
    name: "昆明丨大理丨丽江丨香格里拉 8天7晚",
    prize: 598,
    linkName: "KDLX",
    type:'8天7晚',
    typeName:'昆明丨大理丨丽江丨香格里拉 8天7晚',
    productNo:'yntripdlx',
    typeList:[{
        typeName:'8天7晚'
    }],
    smallImages:'http://dspcloud.spay365.com/383411068f286694dfa7d0608da411bec9b9bebf.jpg',
    bannerList: [
      {
        imgUrl:
          "http://dspcloud.spay365.com/eba70ed237484d6f7c6a999ec778e531785a30bc.png",
      },{
        imgUrl:
          "http://dspcloud.spay365.com/ae054791ae5e094baa20c6644c82208f4ca321e4.png",
      },{
        imgUrl:
          "http://dspcloud.spay365.com/9fbe9b6c6a7ab061ec94ed6d1f1d4e9d29470de5.png",
      },{
        imgUrl:
          "http://dspcloud.spay365.com/ba7f077ccdfe6465c7018190b39d043c2e494f35.png",
      }
    ],
    detailImage: "http://dspcloud.spay365.com/a9e6b7ff14afbeb9f0b7e52b9b5b7db73baaf9fd.jpg",
  },
 {
    name: "昆明丨大理丨丽江丨香格里拉丨泸沽湖 10天9晚",
    prize: 698,
    linkName: "KDLXL",
    type:'10天9晚',
    typeName:'昆明丨大理丨丽江丨香格里拉丨泸沽湖 10天9晚',
    productNo:'yntripdlxl',
    typeList:[{
        typeName:'10天9晚'
    }],
    smallImages:'http://dspcloud.spay365.com/df45562e818d9479c80e866c5135762a31443874.jpg',
    bannerList: [
      {
        imgUrl:
          "http://dspcloud.spay365.com/d06c5c078d6143548a72d48336cc1e74c4ef6b12.png",
      },{
        imgUrl:
          "http://dspcloud.spay365.com/d62ad07468a55f4c73c2878546e6e0f58b5c1c1d.png",
      },{
        imgUrl:
          "http://dspcloud.spay365.com/8c0bd6ed0812f6cc60532af6ab25b12e74960d82.png",
      },{
        imgUrl:
          "http://dspcloud.spay365.com/56fc60175bd7f8fed52aa53b34f60911281c178a.png",
      }
    ],
    detailImage: "http://dspcloud.spay365.com/95512b591d610b0ff88961f073eec0b9c16dfc0a.jpg",
  }
]

export default ynTripList;
//6款产品都在
